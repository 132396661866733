<template>
  <div class="body container">
    <Breadcrumb />
    <h1>{{ this.pageTitle }}</h1>
    <Form @onSubmit="create" />
  </div>
</template>

<script>
import "@/assets/scss/style.scss";
import Breadcrumb from "@/components/Breadcrumb";
import Form from "@/views/monetaryStatement/Form";
import monetaryStatementApi from "@/apis/monetaryStatement";
import formMixin from "@/mixins/form";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  mixins: [formMixin],
  components: {
    Breadcrumb,
    Form
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.createMonetaryStatement");
    }
  },
  methods: {
    async create(monetaryStatement) {
      try {
        await monetaryStatementApi.createMonetaryStatement(monetaryStatement);
        this.$router.push({ name: "monetaryStatement" });
        this.$message.success(this.$t("message.create_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>
